import { gql } from '@apollo/client';

const RegisterCustomQuestionAnswersAndAddOns = gql`
  query RegisterCustomQuestionAnswersAndAddOns(
    $supplierId: UUID!
    $activityId: UUID
    $sessionId: UUID
    $questionIds: [UUID]
    $addonsIncluded: Boolean!
  ) {
    registerSessions(
      supplierId: $supplierId
      activityId: $activityId
      questionIds: $questionIds
      sessionId: $sessionId
    ) {
      id
      sessionBookings {
        id
        questionAnswers {
          id
          question {
            id
            shortTitle
          }
          answer
        }
        sessionAddons @include(if: $addonsIncluded) {
          name
          addonId
        }
        bookingAddons @include(if: $addonsIncluded) {
          name
          addonId
          quantity
        }
      }
    }
  }
`;

export default RegisterCustomQuestionAnswersAndAddOns;
