import { gql } from '@apollo/client';

const CreateBookingSessionsCancellation = gql`
  mutation createBookingSessionsCancellation(
    $bookingSessionIds: [UUID]!
    $reason: CancellationReason!
    $reasonDetails: String
    $sendCustomerComms: Boolean
  ) {
    createBookingSessionsCancellation(
      bookingSessionIds: $bookingSessionIds
      reason: $reason
      reasonDetails: $reasonDetails
      sendCustomerComms: $sendCustomerComms
    ) {
      bookingSessions {
        id
        status
      }
    }
  }
`;

export default CreateBookingSessionsCancellation;
