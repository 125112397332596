import { gql } from '@apollo/client';

const CreateTrialActivityBookingCancellation = gql`
  mutation createTrialActivityBookingCancellation(
    $bookingId: UUID
    $reason: ActivityBookingCancellationEnum!
    $reasonDetails: String
    $sendCustomerComms: Boolean
  ) {
    createTrialActivityBookingCancellation(
      bookingId: $bookingId
      reason: $reason
      reasonDetails: $reasonDetails
      sendCustomerComms: $sendCustomerComms
    ) {
      cancelledActivityBooking
    }
  }
`;

export default CreateTrialActivityBookingCancellation;
