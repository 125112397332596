import { gql } from '@apollo/client';

const RegisterSessionAttendeesQuery = gql`
  query RegisterSessionAttendeesQuery($supplierId: UUID!, $sessionId: UUID!) {
    registerSessions(supplierId: $supplierId, sessionId: $sessionId) {
      id
      sessionBookings {
        id
        attendance
        checkInTime
        checkOutTime
        checkInSupplierAdmin {
          id
          fullName
        }
        checkOutSupplierAdmin {
          id
          fullName
        }
        isManuallyAddedBy
        movedFrom
        child {
          id
          fullName
          birthDate
          isAdult
          guardian {
            fullName
            phoneNumber
            email
          }
          guest {
            id
            fullName
            phoneNumber
            email
          }
        }
        status
        bookingTicket {
          id
          ticketNameAtBooking
          hasFailedPayment
          blockTrialSelected
          ticket {
            id
            name
            ticketType
          }
          booking {
            movedFrom
            creationType
            supplierMarketingOptIn
            additionalInformationEmailStatus
            additionalInformationEmailStatusUpdatedAt
            id
            supplierMarketingOptIn
            status
            pausedAt
            activity {
              id
              bookingType
              subscriptionTrialType
              subscriptionTrialSessionCount
              subscriptionTrialPrice
              ageMonthsStart
              ageMonthsEnd
            }
            payment {
              id
              scheduledAt
            }
          }
          sessions {
            sessionStart
            isFreeTrialSession
            isPaidTrialSession
          }
        }
        hasAddons
        medicalAnswer
        photoConsentAnswer
        notes {
          id
          isActioned
        }
      }
      activityClass {
        id
        name
        isDeleted
      }
    }
  }
`;

export default RegisterSessionAttendeesQuery;
