import { gql } from '@apollo/client';

const QuestionsForRegister = gql`
  query QuestionsForRegister($supplierId: UUID!, $activityId: UUID!) {
    questionsForRegister(supplierId: $supplierId, activityId: $activityId) {
      id
      shortTitle
    }
  }
`;

export default QuestionsForRegister;
